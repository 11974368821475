.Map {
  height: 57.3vh;
  /* width: 80%; */
  position: relative;
  /* margin-top: 20;
  margin-bottom: 20; */
  flex: 1;
}

.MapList {
  height: 90vh;
  /* position: absolute; */
  /* overflow: scroll; */
  /* width: 540px; */
  width: 100%;
}
.List {
  font-size: 12px;
}
.Map2 {
  height: 85vh;
  /* width: 80%; */
  position: relative;
  /* margin-top: 20;
  margin-bottom: 20; */

  flex: 4;
}
body {
  margin: 0px;
}
.container {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-flow: nowrap column;
  overflow: hidden;
}

.panel {
  display: flex;
}

.header {
  min-height: 530px;
  height: 100%;
}
.footer {
  background: white;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align: center;
  justify-content: flex-start;
  overflow: hidden;
}
.footerArea {
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-flow: column;
  overflow-y: auto;
}

.footerBottomBar {
  background: blue;
  color: white;
  padding: 10px;
}
.body {
  background: gray;
  flex-grow: 3;
  display: flex;
  flex-flow: row nowrap;
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  background: white;
  min-width: 0px;
  max-width: 1150px;
  /* width: 120%; */
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}

.withMargin {
  margin: 0px;
  box-sizing: border-box;
}

.content {
  flex-grow: 2;
  /* background: red; */
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: lightblue;
  border: 2px solid gray;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: gray;
  display: flex;
  z-index: 99999;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: visible;
}
